<template>
  <ContentWrapper :links="topLinks">
    <Form inline>
      <FormItem>
        <Button type="primary" @click="$router.push({ name: 'PromoCodeCreatePage' })">发布</Button>
      </FormItem>
      <FormItem>
        <Input v-model="queryForm.keyword" type="text" placeholder="搜索" />
      </FormItem>
      <FormItem>
        <FormItem>
          <Select v-model="queryForm.status" placeholder="状态">
            <Option :value="-1">不限</Option>
            <Option :value="1">正常</Option>
            <Option :value="0">下架</Option>
          </Select>
        </FormItem>
      </FormItem>
      <FormItem>
        <Select v-model="queryForm.type" placeholder="优惠码类型">
          <Option :value="-1">不限</Option>
          <Option :value="1">折扣</Option>
          <Option :value="2">直减</Option>
        </Select>
      </FormItem>
      <FormItem>
        <DatePicker
          v-model="createdAtRange"
          type="daterange"
          placement="bottom-end"
          placeholder="创建日期范围"
          style="width: 200px"
        ></DatePicker>
      </FormItem>
      <!-- <FormItem>
        <Button>重置搜索</Button>
      </FormItem> -->
    </Form>
    <Table stripe :columns="columns" :data="itemList" :loading="tableLoading" @on-row-dblclick="showCopied"></Table>
    <Pager :total="itemListCount" :current.sync="page" />
    <Modal v-model="showItemsDetail" title="商品详情" width="65%">
      <ItemDetail v-if="showItemsDetail" :promoId="currentPromo" ref="itemDetailViewer" />
    </Modal>
    <Modal v-model="showCashBackDetail" title="返现详情" width="75%">
      <CashBackDetail v-if="showCashBackDetail" :promoId="currentPromo" ref="cashBackViewer" />
    </Modal>
    <Modal v-model="showPromoEditor" title="修改优惠码" width="88%" :closable="false">
      <PromoEditor v-if="showPromoEditor" :formData="currentPromoForm" @promoUpdated="promoUpdated" ref="promoEditor" />
      <div slot="footer">
        <Button @click="showPromoEditor=false">取消</Button>
      </div>
    </Modal>
    <Modal v-model="showSharePromoCode" title="商品详情" width="65%">
      <SharePromoCode v-if="showSharePromoCode" :promoCode="currentPromoCode" />
    </Modal>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import Pager from "@/components/Pager.vue"
import { queryPromo, changePromoStatus, deletePromo, publishItem, queryItemIds, queryItemByIds } from "@/api"
import { toHumanDate, editAndRemove, apiUtil, financial, copyToClipboard } from "@/util"
import ItemDetail from "@/components/promocode/ItemDetail.vue"
import CashBackDetail from "@/components/promocode/CashBackDetail"
import PromoEditor from "@/components/promocode/PromoEditor"
import SharePromoCode from "@/components/promocode/SharePromoCode"
import "@/components/tableUtil"

export default {
  name: "ItemQueryPage",
  components: { ContentWrapper, Pager, ItemDetail, CashBackDetail, PromoEditor, SharePromoCode },
  data() {
    return {
      topLinks: [{ title: "优惠码列表", link: this.$route.path }],
      createdAtRange: ["", ""],
      queryForm: {
        keyword: "",
        status: null,
        type: null,
        startTime: null,
        endTime: null,
      },
      page: 1,
      tableLoading: false,
      itemList: [],
      itemListCount: 0,
      showItemsDetail: false,
      showStoresDetail: false,
      showCashBackDetail: false,
      showPromoEditor: false,
      showSharePromoCode: false,
      currentPromo: -1,
      currentPromoCode: "",
      currentPromoForm: {},
      columns: [
        { title: "编号", key: "id", width: 100, maxWidth: 200, fixed: "left" },
        { title: "优惠码", key: "code", width: 100, maxWidth: 200, fixed: "left" },
        { title: "标题", key: "name", width: 200, maxWidth: 200, fixed: "left" },

        {
          title: "数量", width: 60, maxWidth: 60, fixed: "left", render: (h, params) => {
            var quantityLabel = ""
            if (params.row.quantity === 0) {
              quantityLabel = "无限"
            } else {
              quantityLabel = params.row.quantity
            }
            return h("span", quantityLabel)
          },
        },
        {
          title: "现有", width: 60, maxWidth: 60, fixed: "left", render: (h, params) => {
            var quantityLeftLabel = ""
            if (params.row.quantity === 0) {
              quantityLeftLabel = "无限"
            } else {
              quantityLeftLabel = params.row.quantityLeft
            }
            return h("span", quantityLeftLabel)
          },
        },
        {
          title: "单个买家使用次数限制", width: 60, maxWidth: 60, fixed: "left", render: (h, params) => {
            var quantityLabel = ""
            if (params.row.quantityPerBuyer === 0) {
              quantityLabel = "无限"
            } else {
              quantityLabel = params.row.quantityPerBuyer
            }
            return h("span", quantityLabel)
          },
        },
        {
          title: "优惠类型",
          maxWidth: 150,
          minWidth: 100,
          render: (h, params) => {
            var typeLabel = ""
            if (params.row.promotionType === "discount") {
              typeLabel = "折扣 " + financial(params.row.value * 0.01) + "%"
            }
            if (params.row.promotionType === "deduct") {
              typeLabel = "满 " + financial(params.row.condition * 0.01) + "减 " + financial(params.row.value * 0.01)
            }
            return h(
              "span",
              {},
              [typeLabel],
            )
          },
          fixed: "left",
        },
        {
          title: "优惠码绑定",
          maxWidth: 200,
          minWidth: 100,
          render: (h, params) => {
            if (params.row.type === 1 && Array.isArray(params.row.targetIds) && params.row.targetIds.length > 0) {
              return h(
                "a",
                {
                  on: {
                    click: () => {
                      this.showItemsDetail = true
                      this.currentPromo = params.row.id
                    },
                  },
                },
                ["点击查看绑定商品详情"],
              )
            } else if (params.row.type === 2 && Array.isArray(params.row.targetIds) && params.row.targetIds.length > 0) {
              return h(
                "a",
                {
                  on: {
                    click: () => {
                      this.showStoresDetail = true
                      this.currentPromo = params.row.id
                    },
                  },
                },
                ["点击查看绑定商店详情"],
              )
            } else {
              return h("span", "数据损坏，优惠码无效")
            }
          },
          fixed: "left",
        },
        {
          title: "返现",
          maxWidth: 200,
          minWidth: 100,
          render: (h, params) => {
            if (params.row.isCashBack == true) {
              return h(
                "a",
                {
                  on: {
                    click: () => {
                      this.currentPromo = params.row.id
                      this.showCashBackDetail = true
                    },
                  },
                },
                ["点击查看返现详细"],
              )
            } else {
              return h("span", "无返现")
            }
          },
          fixed: "left",
        },

        {
          title: "状态",
          render: (h, params) => {
            return h("StatusHelper", {
              props: { value: params.row.status },
              on: {
                change: async (value) => {
                  await apiUtil.call(this, async () => await changePromoStatus(params.row.id, value))
                },
              },
            })
          },
        },
        {
          title: "过期时间",
          render: (h, p) => {
            if (p.row.expired === 0) {
              return h("span", "永不过期")
            } else {
              return h("DateHolder", {
                props: { time: p.row.expiredDate, defText: "-" },
              })
            }
          },
        },
        {
          title: "创建时间",
          key: "createdAt",
          render: (h, p) => h("div", toHumanDate(p.row.createdAt)),
        },
        {
          title: "操作",
          width: 250,
          render: (h, params) =>
            editAndRemove.call(
              this,
              h,
              params,
              () => {
                this.edit(params.row)
              },
              async () => {
                await this.delete(params.row.id)
              },
              "确定删除吗?",
              [
                h("Button", {
                  props: { type: "primary" },
                  on: {
                    click: () => {
                      this.showCopied(params.row)
                    },
                  },
                }, "复制"),
                h("Button", {
                  props: { type: "info" }, on: {
                    click: () => {
                      this.shareCode(params.row)
                    },
                  },
                }, "分享"),
              ],
            ),
        },
      ],
    }
  },
  computed: {},
  watch: {
    async page() {
      await this.reload()
    },
    async createdAtRange() {
      await this.reload()
    },
    queryForm: {
      deep: true,
      async handler() {
        await this.reload()
      },
    },
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {
      try {
        this.tableLoading = true
        const q = this.queryForm
        let startTime = null,
          endTime = null
        if (this.createdAtRange && this.createdAtRange.length == 2 && this.createdAtRange[0] instanceof Date) {
          startTime = this.createdAtRange[0].getTime()
          endTime = this.createdAtRange[1].getTime()
        }
        let status = null
        if (q.status > -1) {
          status = q.status
        }
        let type = null
        if (q.type > -1) {
          type = q.type
        }

        const { items, count } = await queryPromo(this.page, q.keyword, "", startTime, endTime, status, type)
        this.itemList = items
        this.itemListCount = count
        this.tableLoading = false
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    async edit(row) {
      console.log(row)

      const t = {}
      t.id = row.id
      t.isCashBack = row.isCashBack
      t.promotionType = row.promotionType
      t.quantity = row.quantity
      t.quantityNumber = 0
      if (t.quantity > 0) {
        t.quantity = 1
        t.quantityNumber = row.quantity
      }
      t.quantityPerBuyer = row.quantityPerBuyer
      if (t.quantityPerBuyer > 0) {
        t.quantityPerBuyerNumber = t.quantityPerBuyer
        t.quantityPerBuyer = 1
      }

      t.condition = row.condition
      t.value = row.value
      t.expired = row.expired
      if (t.expired == 0) {
        t.expiredDate = null
      } else {
        t.expiredDate = new Date(row.expiredDate)
      }
      t.targetIds = row.targetIds
      t.cashBackGroup = row.cashBackGroup
      t.name = row.name
      t.desc = row.desc
      t.code = row.code // 优惠码不能修改
      t.status = row.status
      t.createdAt = row.createdAt
      t.discountValue = 0
      t.deductCond = 0
      t.deductValue = 0
      t.cashBackers = []

      if (t.promotionType === "discount") {
        t.discountValue = parseFloat(financial(row.value * 0.01))
      } else if (t.promotionType === "deduct") {
        t.deductCond = parseFloat(financial(row.condition * 0.01))
        t.deductValue = parseFloat(financial(row.value * 0.01))
      }
      if (t.isCashBack) {
        if (Array.isArray(t.cashBackGroup)) {
          var cashBackers = []
          t.cashBackGroup.forEach(function(item) {
            var cashBacker = {}
            cashBacker.targetUserId = item.targetUserId
            cashBacker.cashBackAmount = parseFloat(financial(item.cashBackAmount * 0.01))

            cashBacker.location = item.location
            cashBacker.sellerName = item.sellerName

            var locs = [cashBacker.location.fullAddress,
              cashBacker.location.contact,
              cashBacker.location.phone,
            ]
            cashBacker.promoAddress = locs.join(" ")
            cashBackers.push(cashBacker)
          })

          t.cashBackers = cashBackers
        }
      }
      var _selectedItems = await queryItemByIds(t.targetIds)
      const idMap = await queryItemIds(_selectedItems.map(i => i.itemAlias))
      _selectedItems.forEach(item => {
        item.id = idMap[item.itemAlias]
      })

      t.selectedItems = _selectedItems
      t.type = row.type
      this.currentPromoForm = t
      this.showPromoEditor = true

    },
    async delete(id) {
      try {
        await deletePromo(id)
        await this.reload()
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    async promoUpdated() {
      console.log("promoUpdated")
      this.showPromoEditor = false
      await this.reload()
    },
    showItem(row) {
      this.showItemsDetail = true
      this.currentPromo = row.id
    },
    showCopied(row) {
      console.log(row)
      copyToClipboard(row.code)
      this.$Message.info("优惠码已经复制到剪贴板")
    },
    shareCode(row) {
      this.showSharePromoCode = true
      this.currentPromoCode = row.code
    },
  }
  ,
}
</script>
