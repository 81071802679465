<template>
  <ContentWrapper :links="topLinks">
    <Form :labelWidth="100" :data="form">
      <FormItem>
        <label>点击复制优惠码</label>
        <Row>
          <Col span="6"><Input size="large" v-model="form.promoCode" placeholder="" disabled /></Col>
          <Col span="6">
            <Button @click="showCopied" size="large" type="primary">复制</Button>
          </Col>
        </Row>
      </FormItem>
      <FormItem>
        复制粘贴优惠码 到DingDang App <br />
        Copy and paste this code at DingDang App
      </FormItem>
    </Form>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import { copyToClipboard } from "@/util"

export default {
  name: "SharePromoCode",
  components: { ContentWrapper },
  props: {
    promoCode: {
      type: String,
      default: "",
    },

  },
  data() {
    return {
      topLinks: [{ title: "分享优惠码" }],
      form: {
        promoCode: this.promoCode,
      },
    }
  },
  computed: {},
  watch: {
    async promoCode() {
      this.form.promoCode = this.promoCode
    },
    async category() {
      this.form.category = this.category
    },
    async form() {
      await this.reload()
    },
    async mounted() {
      await this.reload()
    },
  },
  methods: {
    async reload() {
      this.form.promoCode = this.promoCode
    },
    showCopied() {
      console.log(this.promoCode)
      copyToClipboard(this.promoCode)
      this.$Message.info("优惠码已经复制到剪贴板")
    },
  },
}
</script>
