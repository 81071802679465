<template>
  <div>
    <Form inline>
      <FormItem>
        <Input v-model="queryForm.keyword" type="text" placeholder="搜索" />
      </FormItem>
    </Form>
    <Table stripe :columns="columns"
           :data="itemList"
           :loading="tableLoading"
    ></Table>
    <Pager :total="itemListCount" :current.sync="page" />
  </div>
</template>

<script>
import Pager from "@/components/Pager.vue"

import { queryItem, queryItemByIds, queryItemIds, queryPromoItems } from "@/api"
import { toHumanDate, centiToOne, imgHolder } from "@/util"

var status_label = {
  0: "草稿",
  1: "生效",
  2: "售出下架",
  3: "过期",
  4: "禁用",
  5: "封禁",
}

export default {
  name: "PromoCodeItemDetail",
  components: { Pager },
  props: {
    selected: { type: Array, default: () => [] },
    max: { type: Number, default: 100 },
    itemAlias: { type: String, default: "" },
    promoId: { type: Number, default: -1 },
  },
  data() {
    return {
      newSelected: [],
      ids: {},
      itemList: [],
      itemListCount: 0,
      keyword: null,
      tableLoading: false,
      selectedItems: [],
      createdAtRange: ["", ""],
      queryForm: { status: "" },
      columns: [
        { title: "ID", key: "id", width: 80, maxWidth: 80, fixed: "left" },
        { title: "标识", key: "itemAlias", width: 100, maxWidth: 200, fixed: "left" },
        {
          title: "主图",
          width: 100,
          render: (h, params) => {
            return imgHolder(h, params.row.image?.url)
          },
          fixed: "left",
        },
        {
          title: "标题",
          maxWidth: 200,
          minWidth: 100,
          key: "title",
          fixed: "left",
        },
        {
          title: "卖家",
          render(h, p) {
            return h("div", p.row.seller.nickname)
          },
          width: 100,
          fixed: "left",
        },
        {
          title: "价格",
          render(h, params) {
            return h("div", centiToOne(params.row.price))
          },
        },
        {
          title: "商品状态",
          render(h, params) {
            return h("div", status_label[params.row.status])
          },
        },
        {
          title: "创建时间",
          key: "createdAt",
          render: (h, p) => h("div", toHumanDate(p.row.createdAt)),
        },
        {
          title: "上架时间",
          render: (h, p) =>
            h("DateHolder", {
              props: { time: p.row.listedAt, defText: "-" },
            }),
        },
      ],
      page: 1,
    }
  },
  watch: {
    queryForm: {
      deep: true,
      async handler() {
        await this.reload()
      },
    },
    page() {
      this.reload()
    },
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {
      try {
        this.tableLoading = true

        const { items, count } = await queryPromoItems(this.promoId, this.page)
        const idMap = await queryItemIds(items.map(i => i.itemAlias))
        items.forEach(item => {
          item.id = idMap[item.itemAlias]
        })
        this.itemList = items
        this.itemListCount = count
        this.tableLoading = false
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
  },
}
</script>
