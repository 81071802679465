<template>
  <div>
    <Form inline>
      <FormItem>
        <Input v-model="queryForm.name" type="text" placeholder="名称" />
      </FormItem>
      <FormItem>
        <Input v-model="queryForm.nickname" type="text" placeholder="昵称" />
      </FormItem>
      <FormItem>
        <DatePicker
          v-model="createdAtRange"
          type="datetimerange"
          placement="bottom-end"
          placeholder="创建时间范围"
          style="width: 200px"
        ></DatePicker>
      </FormItem>
    </Form>
    <Table
      stripe
      :data="items"
      :columns="tableColumns"
      style="width: 100%"
      :loading="tableLoading"
      ref="selection"
    />
    <Pager :total="itemListCount" :current.sync="page" />
  </div>
</template>

<script>
import Pager from "@/components/Pager.vue"
import { queryUser, queryUserByIds, queryPromoCashBackers } from "@/api"
import { financial } from "@/util"
import "@/components/tableUtil"

export default {
  name: "CashBackSellerDetail",
  components: { Pager },
  props: {
    selected: { type: Array, default: () => [] },
    max: { type: Number, default: 100 },
    currentAlias: {
      type: String,
      default: () => null,
    },
    promoId: { type: Number, default: -1 },
  },
  data() {
    return {
      newSelected: [],
      items: [],
      itemListCount: 0,
      page: 1,
      createdAtRange: ["", ""],
      queryForm: { status: "" },
      selectedUsers: [],
      tableLoading: false,
      m_currentAlias: this.currentAlias,
      tableColumns: [
        { title: "用户ID", key: "id", fixed: "left", width: 100, maxWidth: 100 },
        { title: "用户唯一标识", key: "alias", fixed: "left", width: 300, maxWidth: 300 },
        { title: "通行证 ID", key: "passportUid", fixed: "left", width: 90, maxWidth: 90 },
        { title: "名称", key: "name", width: 100, maxWidth: 100 },
        { title: "昵称", key: "nickname", width: 200, maxWidth: 200 },
        {
          title: "返现金额", width: 100, maxWidth: 200,
          render: (h, p) => {
            return h(
              "span",
              financial(p.row.cashBackAmount * 0.01) + " $",
            )
          },
        },
        {
          title: "返现团长", width: 100, maxWidth: 400,
          key: "contact",
        },
        {
          title: "返现团长手机", width: 100, maxWidth: 400,
          key: "phone",
        },
        {
          title: "返现地址", width: 300, maxWidth: 400,
          key: "address",
        },
        {
          title: "创建时间",
          render: (h, p) =>
            h("DateHolder", {
              props: { time: p.row.createdAt, defText: "-" },
            }),
          width: 100,
          maxWidth: 100,
        },
      ],
    }
  },
  computed: {},
  watch: {
    queryForm: {
      deep: true,
      async handler() {
        await this.reload()
      },
    },
    async page() {
      await this.reload()
    },
    async createdAtRange() {
      await this.reload()
    },
    async newSelected(newV) {

    },
  },
  async mounted() {
    await this.reload()
  },
  methods: {

    async reload() {
      try {
        this.tableLoading = true
        const { items, count } = await queryPromoCashBackers(this.promoId, this.page)
        this.items = items
        this.itemListCount = count
      } catch (e) {
        this.$Message.error(e.message)
      } finally {
        this.tableLoading = false
      }
    },
  },
}
</script>
