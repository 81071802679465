<template>
  <ContentWrapper :links="topLinks">
    <Form inline>
      <FormItem>
        <Button @click="create">添加文章</Button>
      </FormItem>
      <FormItem>
        <Input v-model="queryForm.title" type="text" placeholder="搜索" />
      </FormItem>
      <FormItem>
        <DatePicker
          v-model="createdAtRange"
          type="datetimerange"
          placement="bottom-end"
          placeholder="创建时间范围"
          style="width: 200px"
        ></DatePicker>
      </FormItem>
    </Form>
    <Table stripe :columns="columns" :data="itemList" @on-row-dblclick="showItem" :loading="tableLoading">
      <template #openUrl="{ row }">
        <Button type="info" size="small" @click="openShop(row.storeAlias)">关联店铺</Button>
      </template>
    </Table>
    <Pager :total="itemListCount" :current.sync="page" />
    <Modal v-model="showItemDetail" title="文章详情" @on-ok="onNewSubmit" width="65%">
      <ArticeDetail :alias="currentItem" ref="editor" />
    </Modal>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import Pager from "@/components/Pager.vue"
import { queryArticle, deleteArticle, createArticle, updateArticle, showArticle, hideArticle } from "@/api"
import { toHumanDate, editAndRemove, apiUtil } from "@/util"
import ArticeDetail from "@/components/artice/ArticeDetail.vue"
import "@/components/tableUtil"

export default {
  name: "ArticleList",
  components: { ContentWrapper, Pager, ArticeDetail },
  data() {
    return {
      topLinks: [{ title: "文章列表", link: this.$route.path }],
      createdAtRange: ["", ""],
      queryForm: {
        title: ""
      },
      page: this.$route.query.page ? Number(this.$route.query.page) : 1,
      tableLoading: false,
      itemList: [],
      itemListCount: 0,
      showItemDetail: false,
      currentItem: "",
      columns: [
        { title: "标识", key: "alias", width: 100, maxWidth: 200, fixed: "left" },
        {
          title: "标题",
          maxWidth: 200,
          minWidth: 100,
          render: (h, params) => {
            return h(
              "a",
              {
                on: {
                  click: () => {
                    this.showItemDetail = true
                    this.currentItem = params.row.alias
                  },
                },
              },
              [params.row.title],
            )
          },
          fixed: "left",
        },
        {
          title: "作者",
          render(h, p) {
            return h("div", p.row.author)
          },
          width: 100,
          fixed: "left",
        },
        {
          title: "关联店铺",
          width: 150,
          slot: "openUrl",
          align: "center"
        },
        {
          title: "创建时间",
          key: "createdAt",
          render: (h, p) => h("div", toHumanDate(p.row.createdAt)),
        },
        {
          title: "状态",
          render: (h, params) => {
            return h("StatusHelper", {
              props: { value: params.row.status, on: 1, off: 0 },
              on: {
                change: async (value) => {
                  try {
                    if (value === 1) {
                      await showArticle(params.row.alias)
                    } else {
                      await hideArticle(params.row.alias)
                    }
                  } catch (e) {
                    this.$Message.error(e.message)
                  }
                },
              },
            })
          },
        },
        {
          title: "操作",
          width: 150,
          render: (h, params) =>
            editAndRemove.call(
              this,
              h,
              params,
              () => {
                this.edit(params.row.alias)
              },
              async () => {
                await this.delete(params.row.alias)
              },
            ),
        },
      ]
    }
  },
  computed: {},
  watch: {
    async page() {
      await this.changePage()
    },
    async createdAtRange() {
      await this.reload()
    },
    queryForm: {
      deep: true,
      async handler() {
        await this.changeQueryForm()
      },
    }
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {
      try {
        this.tableLoading = true
        const q = this.queryForm
        let startTime = null,
          endTime = null
        if (this.createdAtRange && this.createdAtRange.length == 2 && this.createdAtRange[0] instanceof Date) {
          startTime = this.createdAtRange[0].getTime()
          endTime = this.createdAtRange[1].getTime()
        }
        let status = null
        if (q.status > -1) {
          status = q.status
        }
        let type = null
        if (q.type > -1) {
          type = q.type
        }
        const { items, count } = await queryArticle({})
        this.itemList = items
        this.itemListCount = count
        this.tableLoading = false
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    edit(alias) {
      this.showItemDetail = true
      this.currentItem = alias
    },
    async delete(alias) {
      try {
        await deleteArticle(alias)
        await this.reload()
        this.$Message.success("删除成功")
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    showItem(row) {
      this.currentItem = row.alias
      this.showItemDetail = true
    },
    async onNewSubmit() {
      apiUtil.call(
        this,
        async () => {
          const editor = this.$refs.editor
          const medias = editor.uploadedImages.map((item, index) => {
            if (index === 0) {
              return { path: item.path, sortOrder: 100 - index, isPrimary: true }
            } else {
              return { path: item.path, sortOrder: 100 - index }
            }
          })
          const formData = Object.assign({}, editor.form, {"medias": medias})
          if (formData.alias) {
            await updateArticle(formData)
            this.$Message.success("编辑成功")
            await this.reload()
          } else {
            await createArticle(formData)
            this.$Message.success("新建成功")
            await this.reload()
          }
        },
        () => {
          this.showItemDetail = true
        },
      )
    },
    openShop(alias) {
      this.$router.push({ name: "StorePublishPage", query: { alias: alias, disabled: true } })
    },
    create() {
      this.showItemDetail = true
      this.currentItem = ""
    },
    async changePage() {
      if (this.$route.query.page === this.page) {
        return
      }
      const query = Object.assign({}, this.queryForm, { page: this.page })
      this.$router.replace({ name: "ArticleList", query: query })
      await this.reload()
    },
    async changeQueryForm() {
      this.page = 1
      const query = Object.assign({}, this.queryForm, { page: this.page })
      this.$router.replace({ name: "ArticleList", query: query })
      await this.reload()
    }
  },
}
</script>
